import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../../services/data.share.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { AdminService } from '../../services/admin.service';
import { apiUrl } from '../../global/global';
import { MessagingService } from '../../services/messaging.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  authorization = "";
  stepLogin = 0;
  stepLogout = 0;
  bankInfo: any;
  activeSubscriptions: any;
  addProduct: any;

  constructor(private dataSharingService: DataSharingService, private router: Router, private admin: AdminService, private messagingService: MessagingService) {
    this.dataSharingService.isLoginChanged.subscribe(value => {
      this.authorization = localStorage.getItem('authorization');
      if (this.authorization != null && this.authorization != '') {
        this.stepLogin = 1;
        this.stepLogout = 0;
        if (this.stepLogin == 1) {
          this.bankCount();
          this.activeSubscriptions = localStorage.getItem('activeSubscriptions');
          this.addProduct = localStorage.getItem('addProduct');
        }
      } else {
        this.stepLogin = 0;
        this.stepLogout = 1;
      }

    });
  }

  ngOnInit() {
    //
  }
  sellPage() {
    if (this.bankInfo.checkPaymentBanks == 0) {
      swal({
        title: 'Add Bank Account',
        text: 'Please link your bank account with Edee Circle so that we will pay you once we find buyer for your deal.',
        confirmButtonText: 'Add Account',
        showCancelButton: true,
        cancelButtonColor: '#EDEDED',
        confirmButtonColor: '#2FC863',
        width: 500,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.router.navigate(['/addAccount']);
        }
      });

    } else if (this.activeSubscriptions == 0 || this.addProduct == "false") {
      const data = {
        deviceType: 'WEB',
        deviceToken: this.getDeviceToken(),
        deviceIdentifier: String(Math.floor(Math.random() * 500000))
      }
      let url = apiUrl._accessTokenLogind;
      this.admin.putData(url, data).subscribe(async (res) => {
        this.activeSubscriptions = res.data.activeUserSubscriptions;
        this.addProduct = res.data.addProductAllowed.toString();
        if (this.activeSubscriptions == 0 && this.addProduct == "false") {
          this.router.navigate(['/setting/subcription']);
        } else {
          localStorage.setItem('activeSubscriptions', res.data.activeUserSubscriptions);
          localStorage.setItem('addProduct', res.data.addProductAllowed.toString());
          this.router.navigate(['/sell']);
        }
      }, err => {
        console.log(err);
      });
      return false
    }
    else {
      this.router.navigate(['/sell']);
    }

  }
  bankCount() {
    let data = {
    }
    let url = apiUrl._getBankCount;
    this.admin.getData(url, data).subscribe(async (res) => {
      this.bankInfo = res.data;
    }, err => {
      console.log(err);
    });

  }
  getDeviceToken() {
    let deviceToken = localStorage.getItem("fcmToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    } else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem("fcmToken");
      return deviceToken.toString();

    }
  }

}
