import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingModule } from 'ngx-loading';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { InterceptorService } from './services/interceptor.service';
import { LightboxModule } from 'ngx-lightbox';
import { LoaderComponent } from './common/loader/loader.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataSharingService } from './services/data.share.service';


import { ModalModule } from 'ngx-bootstrap/modal';

///////////////////////////////////////////
import { MessagingService } from './services/messaging.service';
// import{environment} from './../environments/firebase-environment';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule, AngularFireList, AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
///////////////////////////////////////////



import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { RatingModule } from 'ngx-bootstrap/rating';

import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './external/home/home.component';


import { BuyerUserComponent } from './external/manageDeal/buyer-user/buyer-user.component';
import { SellerUserComponent } from './external/manageDeal/seller-user/seller-user.component';
import { SideBarComponent } from './external/manageDeal/side-bar/side-bar.component';


import { LoginComponent } from './external/login/login.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { AddCardComponent } from './external/add-card/add-card.component';
import { AddAccountComponent } from './external/add-account/add-account.component';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmCoreModule } from '@agm/core';
import { FaqComponent } from './external/faq/faq.component';
import { PrivacyComponent } from './external/privacy/privacy.component';
import { AboutComponent } from './external/about/about.component';
import { TermsComponent } from './external/terms/terms.component';
// import { NotificationComponent } from './external/notification/notification.component';
import { DatePipe } from '@angular/common';

// import { StripeModule } from "stripe-angular";


const config = new AuthServiceConfig([

]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    BuyerUserComponent,
    SellerUserComponent,
    SideBarComponent,
    LoginComponent,
    LoaderComponent,
    PageNotFoundComponent,
    AddCardComponent,
    AddAccountComponent,
    FaqComponent,
    PrivacyComponent,
    AboutComponent,
    TermsComponent,

    // NotificationComponent,


  ],
  imports: [

    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDevKGpAvw3bF07BXFnWP3DeWfs-BRloYA&libraries=places&language=en',
      // libraries: ['places'] 
    }),

    BrowserModule,
    NgxSpinnerModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    LoadingModule,
    NgbModule,
    CommonModule,
    Ng2TelInputModule,
    BrowserAnimationsModule,
    AccordionModule.forRoot(),
    RatingModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    SlickCarouselModule,
    BsDatepickerModule.forRoot(),
    LightboxModule,
    // AngularFontAwesomeModule,
    ToastrModule.forRoot({
      timeOut: 1500,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    SweetAlert2Module.forRoot({
      buttonsStyling: false,
      customClass: 'modal-content',
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn'
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp({

      apiKey: "AIzaSyClys81IKe08WyMGvrqYQkeB_zNCB_8LD4",
      authDomain: "edeecircle-d2a0e.firebaseapp.com",
      databaseURL: "https://edeecircle-d2a0e-default-rtdb.firebaseio.com",
      projectId: "edeecircle-d2a0e",
      storageBucket: "edeecircle-d2a0e.appspot.com",
      messagingSenderId: "169212502385",
      appId: "1:169212502385:web:a984f3df8edc823fcad6d0",
      measurementId: "G-WZELB5MR20"

      // apiKey: 'AIzaSyBKm1KsHyX6OSYwTYD8tDPCghjUDoD5wZw',
      // authDomain: 'edee-circle-a3756.firebaseapp.com',
      // databaseURL: 'https://edee-circle-a3756.firebaseio.com',
      // projectId: 'edee-circle-a3756',
      // storageBucket: 'edee-circle-a3756.appspot.com',
      // messagingSenderId: '984877718112',
      // appId: '1:984877718112:web:784dc93d045a49a9d48105',
      // measurementId: 'G-B4YE60YMVR'
    }),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
  ],
  providers: [MessagingService, DatePipe, AsyncPipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }, NgxSpinnerModule,
    DataSharingService
  ],
  bootstrap: [AppComponent],

})

export class AppModule { }
