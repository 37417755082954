import { TemplateRef, Component, OnInit, Injectable, ViewChild, NgZone, ElementRef, ViewChildren, ContentChild, QueryList } from '@angular/core';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { DataSharingService } from '../../services/data.share.service'
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { loginModel, resetPasswordModel } from '../../shared/models/login-model';
import * as CONSTANT from '../../services/constants';
import { LoginService } from '../login/login.service';
import { apiUrl } from '../../global/global';
import { HttpClient } from '@angular/common/http';
import { MessagingService } from '../../services/messaging.service';
import * as CryptoJS from 'crypto-js';

import { from } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  modalRef: BsModalRef;
  modalRef2: BsModalRef;
  modalRef3: BsModalRef;
  modalRef4: BsModalRef;
  modalRef5: BsModalRef;
  modalRef6: BsModalRef;
  myReactiveForm: FormGroup;
  registerPassword: FormGroup;
  phoneVerification: FormGroup;
  otpVerification: FormGroup;
  Location: FormGroup;

  url;
  event;
  stepList = 1;
  title = 'Show';
  title1 = 'Show';
  title2 = 'Show';
  submitted = false;
  forgotsubmitted = false;
  registerSubmitted = false;
  phoneSubmitted = false;
  otpSubmitted = false;
  locationSubmitted = false;

  rememberme = false;
  isRemember = false;
  forgotPassword: FormGroup;
  tokenFromUI = '0123456789123456';
  loginModel = new loginModel();
  passwordType: string = 'password';
  passwordType1: string = 'password';
  passwordType2: string = 'password';
  passwordShown: boolean = false;
  passwordShown1: boolean = false;
  passwordShown2: boolean = false;
  showError1: boolean = false;
  showError = false;
  showError2 = false;
  showError3 = false;
  showError4 = false;
  showError5 = false;
  show = true;
  public loading = false;
  ipAddress: any;
  country_code = '1';
  slidesPopup = ['', '', ''];
  country_iso_code = 'us';
  mapPlot: any
  noValue=0;
  mapSector: any;
  mapLocality: any;
  mapCity: any;
  mapState: any;
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapLatitude = 40.730610;
  mapLongitude = -73.935242;
  private geoCoder;
  gMaps: any;
  zoom: number;
  searchLocation = {} as any;
  center: any
  iconMap: string = "../../../assets/images/mapIcon1.png";
  disable = 0;
  intervalId: number = 0;
  message: string = '';
  seconds: number = 28;
  clearTimer(): void { clearInterval(this.intervalId); }

  start(): void { this.countDown(); }
  stop(): void {
    this.clearTimer();
    this.message = `Holding at T-${this.seconds} seconds`;
  }

  sliderPopup = { "slidesToShow": 1, "dots": true, "slidesToScroll": 1, autoplay: true, infinite: true, autoplaySpeed: 2000, speed: 1500, };
  config = {
    keyboard: false,
    ignoreBackdropClick: true,
    class: "modal-lg login"
  };
  config1 = {
    keyboard: false,
    ignoreBackdropClick: true,
  };
  config2 = {
    keyboard: false,
    ignoreBackdropClick: true,
    class: "phoneVerified"
  };
  config6 = {
    keyboard: false,
    ignoreBackdropClick: true,
    class: "modal-small"
  };
  accessToken: any;
  Data: any;
  registerData: any;
  registerName: any;
  registerCountrycode: any;
  registerPhone: any;
  registerProfilepic: any;
  email: any;
  mapCityshot:any;
  mapStateshot:any;
  mapcountryshot:any;
  constructor(private ngZone: NgZone, private dataSharingService: DataSharingService, private http: HttpClient, public modalService: BsModalService, public router: Router, public api: LoginService, public formBuilder: FormBuilder, private messagingService: MessagingService) {
    this.myReactiveForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(40)])],
    })

    this.forgotPassword = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
    })
    this.registerPassword = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern(CONSTANT.EMAIL_REGEX)])],
      name: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(40)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(40)])],
      reenterPassword: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(40)])],
      accept: ['', Validators.compose([Validators.required])],
    })

    this.phoneVerification = this.formBuilder.group({
      phone: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(16)])],
    })

    this.otpVerification = this.formBuilder.group({
      otpNumber1: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otpNumber2: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otpNumber3: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
      otpNumber4: ['', Validators.compose([Validators.required, Validators.maxLength(1)])],
    })

    this.Location = this.formBuilder.group({
      location: ['', Validators.compose([Validators.required])],
      flatno: ['', Validators.compose([Validators.required])],
      address2: [''],
      landmark: ['', Validators.compose([Validators.required])],

    })
    this.mapLatitude = parseFloat(localStorage.getItem('latitude'));
    this.mapLongitude = parseFloat(localStorage.getItem('longitude'));


  }

  ngOnInit() {
    this.messagingService.requestPermission();
    let data = localStorage.getItem('authorization');
    // let phoneChecks = localStorage.getItem('phoneNumbercheck');
    // if (phoneChecks == '1')
    //   {
    //     alert("check" );
    //   }

    this.router.navigate// router link change
    let rememberme = localStorage.getItem('rememberme');
    console.log(rememberme, "rememberme");
    if (rememberme == '1') {
      // console.log('checked');
      this.loginModel.email = localStorage.getItem('email');
      this.decryptUsingAES256(localStorage.getItem('password'));
      console.log(this.loginModel.email);
      this.isRemember = true;
      this.rememberme = true;
    }

    // this.setCurrentLocation();

  }

  // private setCurrentLocation() {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.mapLatitude = position.coords.latitude;
  //       this.mapLongitude = position.coords.longitude;
  //       console.log("longitude",this.mapLatitude);
  //       console.log("Latitude", this.mapLongitude);
  //     });
  //   }
  // }

  getCurrentPosition() {
    this.mapLatitude = parseFloat(localStorage.getItem('latitude'));
    this.mapLongitude = parseFloat(localStorage.getItem('longitude'));

  }
  private setCurrentLocation() {

    navigator.geolocation.getCurrentPosition((position) => {
      this.mapLatitude = parseFloat(position.coords.latitude.toString());
      this.mapLatitude = parseFloat(position.coords.longitude.toString());
      this.zoom = 8;
      this.getCurrent(this.mapLatitude, this.mapLatitude);
    });

  }


  /*login api*/
  onSubmit(template, location) {
    this.submitted = true;
    if (this.myReactiveForm.valid) {
      const data = {
        email: this.myReactiveForm.controls.email.value,
        password: this.myReactiveForm.controls.password.value,
        deviceType: 'WEB',
        deviceToken: this.getDeviceToken(),
        deviceIdentifier: '192.168.18.67'
      }
    

      let url = apiUrl._getLogin;
      this.api.putData(url, data).subscribe(async (res) => {
        console.log("*******", res.data);
        this.Data = res.data;
        this.accessToken = res.data.user.accessToken;
        this.modalRef.hide();
        if (res.data.user.phoneVerified == 1) {
          if (res.data.user.landmark != null && res.data.user.landmark != '') {
           
            localStorage.setItem('authorization', "Bearer " + res.data.user.accessToken);
            localStorage.setItem('username', res.data.user.name);
            localStorage.setItem('countryCode', res.data.user.countryCode);
            localStorage.setItem('phoneNumber', res.data.user.phoneNumber);
            localStorage.setItem('profilePic', res.data.user.profilePic);
            localStorage.setItem('useremail', res.data.user.email);
            localStorage.setItem('userID', res.data.user.id);
            localStorage.setItem('countryWeb', res.data.user.countryWeb);
            localStorage.setItem('googleAddress', res.data.user.googleAddress);
            localStorage.setItem('Addressline1', res.data.user.flatNumber);
            localStorage.setItem('Addressline2', res.data.user.addressLine2);
            localStorage.setItem('landmarks', res.data.user.landmark);
            localStorage.setItem('shortNameState', res.data.user.shortNameState);
            localStorage.setItem('shortNameCountry', res.data.user.shortNameCountry);
            localStorage.setItem('city', res.data.user.city);
            localStorage.setItem('pincode', res.data.user.pincode);
            localStorage.setItem('shortNameCity', res.data.user.shortNameCity);
            localStorage.setItem('state', res.data.user.state);
            localStorage.setItem('isPremium', res.data.user.isPremium);
            localStorage.setItem('activeSubscriptions', res.data.activeUserSubscriptions);
            localStorage.setItem('addProduct', res.data.addProductAllowed.toString());

            
            this.dataSharingService.isLoginChanged.next(true);
          } else {
            this.openModallocation(location);
            this.getCurrent(this.mapLatitude, this.mapLongitude);
          }

        }
        else {
          this.modalRef.hide();
          this.openModal(template)
        }
        const email = localStorage.getItem('email');
        if (this.rememberme) {
          let encrptedPassword = await this.encryptUsingAES256(this.loginModel.password);
          localStorage.setItem('rememberme', '1');
          localStorage.setItem('email', this.myReactiveForm.controls.email.value);
          localStorage.setItem('password', String(encrptedPassword));
        } else {
          localStorage.setItem('rememberme', '0');
          localStorage.setItem('email', '');
          localStorage.setItem('password', '');
        }

      },
        err => {
          this.api.toast(err.error.message, '');
          this.loading = false;

        });
    } else {
      this.showError = true;
    }
  }

  /*Forgot api*/
  onSubmitForgot() {
    this.forgotsubmitted = true;
    if (this.forgotPassword.valid) {
      const data = {
        email: this.forgotPassword.controls.email.value,
        deviceType: 'WEB',
        deviceToken: this.getDeviceToken(),
        deviceIdentifier: '192.168.18.67'
      }
      let url = apiUrl._getForgot;
      this.api.forgotData(url, data).subscribe(async (res) => {
        this.api.toast1('Email successfully sent', '');
        this.forgotPassword.patchValue({
          email: '',
        });

        setTimeout(() => {
          this.stepList = 1;
        }, 3200);

      }, err => {
        this.api.toast(err.error.message, '');
        this.loading = false;
      });

    } else {
      this.showError = true;
    }
  }

  /*register */
  onSubmitregister(template) {

    this.registerSubmitted = true;
    if (this.registerPassword.valid) {
      if (this.registerPassword.controls.password.value == this.registerPassword.controls.reenterPassword.value) {
        const data = {
          email: this.registerPassword.controls.email.value,
          name: this.registerPassword.controls.name.value,
          password: this.registerPassword.controls.password.value,
          deviceType: 'WEB',
          deviceToken: this.getDeviceToken(),
          deviceIdentifier: '192.168.18.67'
        }

        let url = apiUrl._getRegister;
        this.api.registerData(url, data).subscribe(async (res) => {
          console.log(res.data);
          this.registerData = res.data;
          this.accessToken = res.data.accessToken;
          this.registerName = res.data.name;
          this.email = res.data.email;


          this.modalRef.hide();
          this.openModal(template);
        },
          err => {
            this.api.toast(err.error.message, '');
            this.loading = false;

          });
      } else {
        this.showError1 = true;
        setTimeout(() => {
          this.showError1 = false;
        }, 5000);
      }

    } else {
      this.showError2 = true;

    }

  }

  /*phonee api*/
  onSubmitphone(otp) {
    this.phoneSubmitted = true;
    if (this.phoneVerification.valid) {
      const data = {

        countryCode: this.country_code,
        countryWeb: this.country_iso_code,
        phoneNumber: this.phoneVerification.controls.phone.value,

      }
      localStorage.setItem('authorization', "Bearer " + this.accessToken);
      let url = apiUrl._getPhone;
      this.api.phoneData(url, data).subscribe(async (res) => {
        console.log(res.data);
        this.start();
        this.countDown();
        this.disable = 0;
        this.modalRef2.hide();
        this.openModalotp(otp);
        localStorage.setItem('authorization', "");
      }, err => {
        this.api.toast(err.error.message, '');
        this.loading = false;
      });

    } else {
      this.showError3 = true;
    }
    localStorage.setItem('authorization', "");
  }

  /*Otp api*/
  otpSubmit(location, phoneVerify) {
    this.otpSubmitted = true;
    if (this.otpVerification.valid) {
      const data = {

        otp: this.otpVerification.controls.otpNumber1.value + this.otpVerification.controls.otpNumber2.value + this.otpVerification.controls.otpNumber3.value + this.otpVerification.controls.otpNumber4.value,
      }
      let url = apiUrl._getOtp;
      localStorage.setItem('authorization', "Bearer " + this.accessToken);
      this.api.otpData(url, data).subscribe(async (res) => {
        this.modalRef3.hide();
        this.openModalPhone(phoneVerify);
        localStorage.setItem('authorization', "");
        setTimeout(() => {
          this.modalRef5.hide();
          this.openModallocation(location);
          this.getCurrent(this.mapLatitude, this.mapLongitude);
        }, 5000);



      }, err => {
        this.api.toast(err.error.message, '');
        this.loading = false;
      });

    } else {
      this.showError4 = true;

    }

    localStorage.setItem('authorization', "");
  }

  /*resend Otp api*/
  resendOtp(makeaCall) {
    this.clearTimer();
    this.modalRef3.hide();
    this.openModalCall(makeaCall);
    // let url = apiUrl._getResend;
    // localStorage.setItem('authorization', "Bearer " + this.accessToken);
    // const data = {
    //   countryCode: this.country_code,
    //   phoneNumber: this.phoneVerification.controls.phone.value,
    // }
    // this.api.otpResend(url, data).subscribe(async (res) => {
    //   console.log(res);


    // }, err => {
    //   this.api.toast(err.error.message, '');
    //   this.loading = false;
    // });
    // localStorage.setItem('authorization', "");
  }
  openModalCall(makeaCall) {
    this.modalRef6 = this.modalService.show(
      makeaCall, this.config6,

    );
  }


  /*location*/
  locationSubmit() {

    this.locationSubmitted = true;
    if (this.Location.valid) {
      const data = {
        flatNumber: this.Location.controls.flatno.value,
        landmark: this.Location.controls.landmark.value,
        addressLine2: this.Location.controls.address2.value,
        countryCode: this.country_code || this.Data.user.countryCode,
        phoneNumber: this.phoneVerification.controls.phone.value || this.Data.user.phoneNumber,
        latitude: this.mapLatitude.toString(),
        longitude: this.mapLongitude.toString(),
        country: this.mapcountry,
        state: this.mapState,
        city: this.mapCity,
      }
      console.log("data", data);
      let url = apiUrl._getPhone;
      localStorage.setItem('authorization', "Bearer " + this.accessToken);
      this.api.locationData(url, data).subscribe(async (res) => {

        console.log('*********', res.data)
        this.modalRef4.hide();
        localStorage.setItem('authorization', "Bearer " + this.accessToken);
        localStorage.setItem('username', this.registerName || this.Data.user.name);
        localStorage.setItem('countryCode', this.country_code || this.Data.user.countryCode);
        localStorage.setItem('phoneNumber', this.phoneVerification.controls.phone.value || this.Data.user.countryCode);
        localStorage.setItem('useremail', this.email || this.Data.user.email);
        localStorage.setItem('userID', res.data.id || this.Data.user.id);
        localStorage.setItem('countryWeb', res.data.countryWeb || this.Data.user.countryWeb);
        localStorage.setItem('profilePic', "null" || this.Data.user.profilePic);
        localStorage.setItem('latitude', this.mapLatitude.toString());
        localStorage.setItem('longitude', this.mapLongitude.toString());
        localStorage.setItem('googleAddress', res.data.googleAddress);
        localStorage.setItem('Addressline1', res.data.flatNumber);
        localStorage.setItem('Addressline2', res.data.addressLine2);
        localStorage.setItem('landmarks', res.data.landmark);
        localStorage.setItem('shortNameState', res.data.shortNameState);
        localStorage.setItem('shortNameCountry', res.data.shortNameCountry);
        localStorage.setItem('city', res.data.city);
        localStorage.setItem('pincode', res.data.pincode);
        localStorage.setItem('shortNameCity', res.data.shortNameCity);
        localStorage.setItem('state', res.data.state);
        localStorage.setItem('isPremium', "0");
        localStorage.setItem('activeSubscriptions', "0" );
        localStorage.setItem('addProduct', "false");
        this.dataSharingService.isLoginChanged.next(true);
      }, err => {
        this.api.toast(err.error.message, '');
        this.loading = false;
        localStorage.setItem('authorization', "");
      });

    } else {
      this.showError5 = true;
      localStorage.setItem('authorization', "");
    }

  }

  /*remmeber me*/
  changeRememberme(event) {

    this.rememberme = event.target.checked;
  }

  /*password  encrypt and  decrypt*/
  encryptUsingAES256(value) {
    return new Promise((resolve, reject) => {
      let ciphertext = CryptoJS.AES.encrypt(value, this.tokenFromUI);
      resolve(ciphertext);
    });

  }
  decryptUsingAES256(value) {
    let bytes = CryptoJS.AES.decrypt(value.toString(), this.tokenFromUI);
    let plaintext = bytes.toString(CryptoJS.enc.Utf8);
    this.loginModel.password = plaintext;
  }



  /*login, forgot, register show hide */

  forgot() {
    this.stepList = 2;
    this.showError = false;
  }
  backToLogin() {
    this.stepList = 1;
    this.showError = false;
  }
  registerUser() {
    this.stepList = 3;
    this.showError = false; 
  }


  /*login password show hide */
  public togglePassword() {
    if (this.passwordShown) {

      this.passwordShown = false;
      this.passwordType = 'password';
      this.title = 'Show';
    } else {
      this.passwordShown = true;
      this.passwordType = 'text';
      this.title = 'Hide';
    }
  }
  /*register password show hide */
  public togglePassword1() {
    if (this.passwordShown1) {

      this.passwordShown1 = false;
      this.passwordType1 = 'password';
      this.title1 = 'Show';
    } else {
      this.passwordShown1 = true;
      this.passwordType1 = 'text';
      this.title1 = 'Hide';
    }
  }

  public togglePassword2() {
    if (this.passwordShown2) {

      this.passwordShown2 = false;
      this.passwordType2 = 'password';
      this.title2 = 'Show';
    } else {
      this.passwordShown2 = true;
      this.passwordType2 = 'text';
      this.title2 = 'Hide';
    }
  }
  /*Wrong number */
  wrongNumber(template) {
    this.modalRef3.hide();
    this.openModal(template);
  }


  /*model */
  openModalWithClass(add) {
    this.modalRef = this.modalService.show(
      add, this.config
    );
  }


  /*model phone*/
  openModal(template) {
    this.modalRef2 = this.modalService.show(
      template, this.config1
    );
  }
  /*model otp*/
  openModalotp(otp) {
    this.modalRef3 = this.modalService.show(
      otp, this.config1
    );
  }
  /*model otp*/
  openModalPhone(phoneVerify) {
    this.modalRef5 = this.modalService.show(
      phoneVerify, this.config2
    );
  }

  /*model otp*/
  openModallocation(location) {
    this.modalRef4 = this.modalService.show(
      location, this.config1
    );

    this.geoCoder = new google.maps.Geocoder;
    this.getCurrent(this.mapLatitude, this.mapLongitude);
  }

  /*country code */


  onCountryChange(event) {

    this.country_code = '+' + event.dialCode;
    console.log("country code", this.country_code);
    this.country_iso_code = event.iso2;

  }

  telInputObject(obj) {
    obj.intlTelInput('setCountry', this.country_iso_code);
  }

  hasError(event) { }
  getNumber(event) {

  }
  /*number only */
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


  /*map */
  handleAddressChange(gMap) {
    this.mapLatitude = gMap.geometry.location.lat();
    this.mapLongitude = gMap.geometry.location.lng();
    console.log(gMap);
    for (let i = 0; i < gMap.address_components.length - 1; i++) {
      let types = gMap.address_components[i].types
      //plot no
      if (types.indexOf('subpremise') != -1) {
        this.mapPlot = gMap.address_components[i].long_name
      }
      //Sector
      if (types.indexOf('neighborhood') != -1) {
        this.mapSector = gMap.address_components[i].long_name
      }
      //
      if (types.indexOf('sublocality_level_1') != -1) {
        this.mapLandmark = gMap.address_components[i].long_name
        console.log('l', this.mapLandmark);
      }
      if (types.indexOf('locality') != -1) {
        this.mapLocality = gMap.address_components[i].long_name
        console.log('Loc', this.mapLocality);
      }
      //city
      if (types.indexOf('administrative_area_level_2') != -1) {
        this.mapCity = gMap.address_components[i].long_name
        this.mapCityshot = gMap.address_components[i].short_name
        console.log('c', this.mapCity);
        console.log('c', this.mapCityshot);
      }
      //state
      if (types.indexOf('administrative_area_level_1') != -1) {
        this.mapState = gMap.address_components[i].long_name
        this.mapStateshot = gMap.address_components[i].short_name
        console.log('c', this.mapState);
        console.log('c', this.mapStateshot);
      }
      if (types.indexOf('country') != -1) {
        this.mapcountry = gMap.address_components[i].long_name
        this.mapcountryshot = gMap.address_components[i].short_name
        console.log('c', this.mapcountry);
        console.log('c', this.mapcountryshot);
      }

      // console.log('last',(types.indexOf('postal_code') == -1) )
      // console.log('last2', gMap.address_components[i].long_name);
      if (types.indexOf('postal_code') == -1) {
        this.mapPostal = gMap.address_components[i].long_name
        console.log('postal2', this.mapPostal);
      }

    }


    // console.log( adr_address);
    this.Location.patchValue({
      flatno: this.mapPlot,
      landmark: this.mapLocality,

    });

  }
  onLocationmarker(event) {
    console.log(event);
    this.mapLatitude = event.coords.lat;
    this.mapLongitude = event.coords.lng;
  }

  /*CURRENT LOCATION */
  getCurrent(latitude, longitude) {
    console.log('map', this.gMaps);
    this.geoCoder.geocode({ 'location': { lat: parseFloat(latitude), lng: parseFloat(longitude) } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        let types = this.gMaps
        console.log(types);

        if (results[0]) {
          this.zoom = 12;
          // this.addressCurrent = results[0].formatted_address;
          // this.ngZone.run(() => {
          //   this.addressCurrent = results[0].formatted_address;
          // });
          // console.log(this.addressCurrent);
          this.Location.patchValue({
            'location': results[0].formatted_address,
          })
          this.ngZone.run(() => {
            this.Location.patchValue({
              'location': results[0].formatted_address,
            })
          });
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getDeviceToken() {
    let deviceToken = localStorage.getItem("fcmToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    } else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem("fcmToken");
      return deviceToken.toString();

    }
  }

  private countDown(): void {
    this.clearTimer();

    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      if (this.seconds === 0) {
        this.disable = 1;
        this.clearTimer();
      } else {
        if (this.seconds < 0) { this.seconds = 28; } // reset

      }
    }, 1000);
  }
  checkCall(callValue, otp) {
    this.openModalotp(otp);
    this.modalRef6.hide();

    let url = apiUrl._getResend;
    localStorage.setItem('authorization', "Bearer " + this.accessToken);
    const data = {
      countryCode: this.country_code,

      phoneNumber: this.phoneVerification.controls.phone.value,
      makeCall: callValue,
    }
    this.api.otpResend(url, data).subscribe(async (res) => {
      console.log(res);
      this.disable = 0;
      this.start();
    
    }, err => {
      this.api.toast(err.error.message, '');
      this.loading = false;
    });
    localStorage.setItem('authorization', "");
  }
  // goTotreampage(){
  //   terms-and-conditions
  // }
}