import { TemplateRef, Component, OnDestroy, OnInit, Injectable, ViewChild, NgZone, ElementRef, ViewChildren, ContentChild, QueryList } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from '../../services/admin.service';
import { DataSharingService } from '../../services/data.share.service'
// import { LoginComponent } from '../../external/login/login.component';

import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// import { LoginService } from '../../external/login/login.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { LoginComponent } from 'src/app/external/login/login.component';
import { buyerService } from '../../external/buy-phone/buyer.service';
import { apiUrl } from '../../global/global';
import { ImagePathService } from '../../services/image-path.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MessagingService } from '../../services/messaging.service';
import { MapsAPILoader, MouseEvent } from '@agm/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],


})
export class HeaderComponent implements OnInit, OnDestroy {
  firstName = "";
  authorization = "";
  stepLogin = 0;
  stepLogout = 0;
  bsModalRef: BsModalRef;
  bsModalRef1: BsModalRef;
  flatNumber: any;
  city: any;
  landmark: any;
  modalRef4: BsModalRef;
  imgUrl: any;
  mapPlot: any
  mapSector: any;
  mapLocality: any;
  mapCity: any;
  mapState: any;
  mapcountry: any;
  mapPostal: any;
  mapLandmark: any;
  mapLatitude = 40.730610;
  mapLongitude = -73.935242;
  center: any
  showNotification = false;
  Location: FormGroup;
  locationSubmitted = false;
  showError5 = false;
  allAddress: any;
  iconMap: string = "../../../assets/images/mapIcon1.png";
  status: boolean = false;
  searchData: any;
  userId: any;
  searching: string = '';
  allDeals: any = [];
  showNorecord = 0;
  showSearchList = 0;
  navbarOpen = false;
  bankInfo: any;
  countryWeb: any;
  latitude: number;
  longitude: number;
  private geoCoder;
  gMaps: any;
  zoom: number;
  mapStateshort: any;
  newAddress = '';
  activeSubscriptions: any;
  addProduct: any;
  addressCurrent = "Vestal new york";
  // mapLatitude = 40.730610;
  // mapLongitude = -73.935242;
  public subscribers: any = {};

  @ViewChild('search') public searchElementRef: ElementRef;
  @ViewChild('currentAddress', { static: false }) currentAddress: ModalDirective;
  constructor(private ngZone: NgZone, private mapsAPILoader: MapsAPILoader, public api: buyerService, public apiImage: ImagePathService, private modalService: BsModalService, private admin: AdminService, private dataSharingService: DataSharingService, private router: Router, public formBuilder: FormBuilder, private messagingService: MessagingService) {

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };




    this.dataSharingService.isLoginChanged.subscribe(value => {
      this.firstName = localStorage.getItem('username');
      this.authorization = localStorage.getItem('authorization');
      this.userId = localStorage.getItem('userID');
      this.countryWeb = localStorage.getItem('countryWeb');
      if (this.authorization != null && this.authorization != '') {
        this.stepLogin = 1;
        this.stepLogout = 0;
        if (this.stepLogin == 1) {
          this.getAllNotification();
          this.activeSubscriptions = localStorage.getItem('activeSubscriptions');
          this.addProduct = localStorage.getItem('addProduct');
          this.bankCount();
        }
      } else {
        this.stepLogin = 0;
        this.stepLogout = 1;
      }

      this.latitude = parseFloat(localStorage.getItem('latitude'));
      this.longitude = parseFloat(localStorage.getItem('longitude'));
      // this.geoCoder = new google.maps.Geocoder;
      // this.getCurrent(this.latitude, this.longitude)
    });
    this.dataSharingService.isUser.subscribe(value => {
      this.latitude = parseFloat(localStorage.getItem('latitude'));
      this.longitude = parseFloat(localStorage.getItem('longitude'));
      // this.geoCoder = new google.maps.Geocoder;
      // this.getCurrent(this.latitude, this.longitude)

    });





    this.Location = this.formBuilder.group({
      location: [],
    })
    localStorage.setItem("userSelectlocation", JSON.stringify({ 'Longitude': this.mapLongitude, 'Latitude': this.mapLatitude }));
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
  }

  ngOnInit() {
    this.getImage();
    this.location();
  }
  ngOnDestroy() {
    this.dataSharingService.isLoginChanged.next(false);
    this.dataSharingService.isUser.next(false);

    // this.subscribers.isLoginChanged1.unsubscribe();
    // this.subscribers.isUser.unsubscribe();
  }

  /*CHECK LOCATION */
  location() {
    let addressCheck = localStorage.getItem('addressStatus');
    if (addressCheck == '1') {
      this.latitude = parseFloat(localStorage.getItem('latitude'));
      this.longitude = parseFloat(localStorage.getItem('longitude'));
      this.geoCoder = new google.maps.Geocoder;
      this.getCurrent(this.latitude, this.longitude)
    } else {
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
      });

    }

  }
  /*SET LOCATION */
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        localStorage.setItem('latitude', this.latitude.toString());
        localStorage.setItem('longitude', this.longitude.toString());
        localStorage.setItem('addressStatus', '0');
        this.dataSharingService.addressUpdate.next(true);
        this.getCurrent(this.latitude, this.longitude)
      }, (err) => {
        console.log(err);
        this.latitude = 42.0850747;
        this.longitude = -76.053813;
        localStorage.setItem('latitude', this.latitude.toString());
        localStorage.setItem('longitude', this.longitude.toString());
        this.getCurrent(this.latitude, this.longitude)
        localStorage.setItem('addressStatus', '0');
        this.dataSharingService.addressUpdate.next(true);
      });
    }

  }
  /*CURRENT LOCATION */
  getCurrent(latitude, longitude) {
    console.log('map', this.gMaps);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        let types = this.gMaps
        console.log(types);

        if (results[0]) {
          this.zoom = 12;
          this.addressCurrent = results[0].formatted_address;
          this.ngZone.run(() => {
            this.addressCurrent = results[0].formatted_address;
          });
          console.log(this.addressCurrent);
          this.Location.patchValue({
            'location': results[0].formatted_address,
          })
          this.ngZone.run(() => {
            this.Location.patchValue({
              'location': results[0].formatted_address,
            })
          });
        } else {
          this.api.toast('', 'No results found');
        }
      } else {
        this.api.toast('', 'Your Daily quota limit has exceeded');
      }

    });
  }



  showLogin() {
    this.bsModalRef = this.modalService.show(LoginComponent, {
      keyboard: false,
      ignoreBackdropClick: true,
      class: "modal-lg login"
    });
    this.bsModalRef.content.modalRef = this.bsModalRef;
  }


  logOut() {
    swal({
      text: 'ARE YOU SURE TO LOGOUT FROM THE WEBSITE?',
      confirmButtonText: 'Logout',
      showCancelButton: true,
      cancelButtonColor: '#EDEDED',
      confirmButtonColor: '#2FC863',
      width: 400,
      position: 'top',


    }).then((result) => {
      if (result.value) {
        let data = {
          deviceType: 'WEB',
          deviceToken: this.getDeviceToken(),
          deviceIdentifier: '192.168.18.67'
        }
        let url = apiUrl._logout;
        this.admin.putData(url, data).subscribe(async (res) => {
          localStorage.removeItem('authorization');
          localStorage.removeItem('username');
          localStorage.removeItem('phoneChecks');
          localStorage.removeItem('countryCode');
          localStorage.removeItem('countryName');
          localStorage.removeItem('phoneNumber');
          localStorage.removeItem('profilePic');
          localStorage.removeItem('useremail');
          localStorage.removeItem('countryWeb');
          localStorage.removeItem('userID');
          localStorage.removeItem('googleAddress');
          localStorage.removeItem('Addressline1');
          localStorage.removeItem('Addressline2');
          localStorage.removeItem('landmarks');
          localStorage.removeItem('shortNameState');
          localStorage.removeItem('shortNameCountry');
          localStorage.removeItem('city');
          localStorage.removeItem('pincode');
          localStorage.removeItem('isPremium');
          localStorage.removeItem('fcmToken');
          localStorage.removeItem('activeSubscriptions');
          localStorage.removeItem('addProduct');
          this.router.navigate(['/index']);
          this.dataSharingService.isLoginChanged.next(false);


        }, err => {
          console.log(err);
        })


      }
    });
  }


  // openModalWithClass(add: TemplateRef<any>) { 
  //   this.modalRef = this.modalService.show(
  //     add, Object.assign({}, { class: '' })
  //   ); 
  // }



  /*MAP */
  getAddress(latitude, longitude) {
    console.log('map', this.gMaps);
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        for (let i = 0; i < this.gMaps.address_components.length; i++) {

          let types = this.gMaps.address_components[i].types
          console.log(types);
          //plot no

          for (var j = 0; j < types.length; j++) {


            if (types.indexOf('subpremise') != -1) {
              this.mapPlot = this.gMaps.address_components[i].long_name
              console.log(this.mapPlot);
            }
            //Sector
            if (types.indexOf('neighborhood') != -1) {
              this.mapSector = this.gMaps.address_components[i].long_name
            }

            if (types.indexOf('sublocality_level_1') != -1) {
              this.mapLandmark = this.gMaps.address_components[i].long_name
            }
            if (types.indexOf('locality') != -1) {
              this.mapLocality = this.gMaps.address_components[i].long_name
            }
            //city
            if (types.indexOf('administrative_area_level_2') != -1) {
              this.mapCity = this.gMaps.address_components[i].long_name,
                console.log(this.mapCity);
            }
            //state
            if (types.indexOf('administrative_area_level_1') != -1) {
              this.mapState = this.gMaps.address_components[i].long_name,
                this.mapStateshort = this.gMaps.address_components[i].short_name
              console.log(this.mapState);
            }
            if (types.indexOf('country') != -1) {
              this.mapcountry = this.gMaps.address_components[i].long_name,
                console.log(this.mapcountry);
            }


            if (types.indexOf('postal_code') != -1) {
              this.mapPostal = this.gMaps.address_components[i].long_name,
                console.log('23', this.mapPostal);
            }
          }

        }

        this.Location.patchValue({
          'location': this.gMaps.formatted_address,


        })

        if (results[0]) {
          this.zoom = 12;
          this.newAddress = results[0].formatted_address;
          console.log(this.newAddress);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  /*MODAL OPEN AUTO SEARCH */
  addressModal() {
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          this.gMaps = place;
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)
        });
      });
      autocomplete.setComponentRestrictions
    });
    this.currentAddress.show();

  }

  hideAddress() {
    this.currentAddress.hide();
    this.ngZone.run(() => {

      if (this.newAddress != '') {
        this.addressCurrent = this.newAddress;
      } else {
        this.addressCurrent;
      }

    });

    localStorage.setItem('latitude', this.latitude.toString());
    localStorage.setItem('longitude', this.longitude.toString());
    localStorage.setItem('addressStatus', '1');
    this.dataSharingService.addressUpdate.next(true);
  }


  onLocationmarker(event) {
    //  console.log(event);
    this.mapLatitude = event.coords.lat;
    this.mapLongitude = event.coords.lng;

  }

  /*model*/
  openModallocation(location) {
    this.modalRef4 = this.modalService.show(
      location
    );
    this.addressModal();
  }
  closeFirstModal(location) {
    this.modalRef4.hide();
  }

  clickEvent() {
    this.status = !this.status;
  }

  getBrand() {
    const data = {
      limit: 20,
      paginateType: "ALL",
      search: this.searching.trim(),
      sortType: "DEFAULT",

    }
    let url = apiUrl._getAllBrands;
    this.api.brandData(url, data).subscribe(async (res) => {
      this.allDeals = res.data.allDeals;
      console.log(this.allDeals, res.data.allDealsCount);
      if (res.data.allDealsCount == 0) {
        this.showNorecord = 1;
      } else {
        this.showNorecord = 0;
      }


    }, err => {
      console.log(err);
    });


  }
  /*search function */


  onSearchChange(searchValue: string): void {
    this.searching = searchValue;
    this.getBrand();
    this.showSearchList = 1;
    this.bodyClass();
    if (this.searching == "") {
      this.showSearchList = 0;
    }
  }

  searchClear() {

    this.searching = '';
    this.showSearchList = 0;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('overflowAuto');
  }

  /*get Image url */
  getImage() {
    const data = {
    }
    let url = apiUrl._getImageurl;
    this.apiImage.imagePath(url, data).subscribe(async (res) => {
      this.imgUrl = res.data.s3Url + (res.data.s3Folders.product);
    }, err => {

    });
  }
  bodyClass() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('overflowAuto');
  }

  /*side bar menu */
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  /*router links */
  routerLinks(id) {
    console.log('buyPhone/phoneDetails/' + id);
    this.router.navigate(['buyPhone/phoneDetails/' + id], { replaceUrl: true });

    this.dataSharingService.isSearch.next(true);
  }

  getAllNotification() {
    let data = {
      limit: 100000,
      skip: 0
    }
    let url = apiUrl._getNotification;
    this.admin.getData(url, data).subscribe(async (res) => {
      let notification = res.data
      for (let data of notification.listing) {
        if (data.isRead == 0) {
          this.showNotification = true;
          return false;
        }
        else {
          this.showNotification = false;
        }
      }

    }, err => {
      console.log(err);
    });
  }

  sellPage() {
    this.navbarOpen = !this.navbarOpen;
    console.log(this.addProduct, this.activeSubscriptions, "this.activeSubscriptions===")
    if (this.bankInfo.checkPaymentBanks == 0) {
      this.bankCount();
      return false
    } else if (this.activeSubscriptions == 0 || this.addProduct == "false") {
      const data = {
        deviceType: 'WEB',
        deviceToken: this.getDeviceToken(),
        deviceIdentifier: String(Math.floor(Math.random() * 500000))
      }
      let url = apiUrl._accessTokenLogind;
      this.admin.putData(url, data).subscribe(async (res) => {
        console.log(res.data, "res======")
        this.activeSubscriptions = res.data.activeUserSubscriptions;
        this.addProduct = res.data.addProductAllowed.toString();
        console.log(this.addProduct, this.activeSubscriptions, "this.activeSubscriptions")
        if (this.activeSubscriptions == 0 && this.addProduct == "false") {
          this.router.navigate(['/setting/subcription']);
        } else {
          localStorage.setItem('activeSubscriptions', res.data.activeUserSubscriptions);
          localStorage.setItem('addProduct', res.data.addProductAllowed.toString());
          this.router.navigate(['/sell']);
        }
      }, err => {
        console.log(err);
      });
      return false
    }
    else {
      this.router.navigate(['/sell']);
    }
  }
  bankCount() {
    let data = {
    }
    let url = apiUrl._getBankCount;
    this.admin.getData(url, data).subscribe(async (res) => {
      this.bankInfo = res.data;
      if (this.bankInfo.checkPaymentBanks == 0) {
        this.addbankMsg()
      }
      console.log(this.bankInfo, "notification====")


    }, err => {
      console.log(err);
    });

  }

  addbankMsg() {
    swal({
      title: 'Add Bank Account',
      text: 'Please link your bank account with Edee Circle so that we will pay you once we find buyer for your deal.',
      confirmButtonText: 'Add Account',
      showCancelButton: true,
      cancelButtonColor: '#EDEDED',
      confirmButtonColor: '#2FC863',
      width: 500,
      position: 'top',
    }).then((result) => {
      if (result.value) {
        this.router.navigate(['/addAccount']);
      }
    });
  }



  getDeviceToken() {
    let deviceToken = localStorage.getItem("fcmToken");
    if ((deviceToken) && (deviceToken != '')) {
      return deviceToken;
    } else {
      this.messagingService.requestPermission();
      deviceToken = localStorage.getItem("fcmToken");
      return deviceToken.toString();
    }
  }

}
