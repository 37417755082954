
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './external/home/home.component';

import { SellerUserComponent } from './external/manageDeal/seller-user/seller-user.component';
import { BuyerUserComponent } from './external/manageDeal/buyer-user/buyer-user.component';
import { AddCardComponent } from './external/add-card/add-card.component';
import { AddAccountComponent } from './external/add-account/add-account.component';
import { LoginComponent } from './external/login/login.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { FaqComponent } from './external/faq/faq.component';
import { PrivacyComponent } from './external/privacy/privacy.component';
import { AboutComponent } from './external/about/about.component';
import { TermsComponent } from './external/terms/terms.component';

import { AuthGuard } from './services/auth.guard';



const routes: Routes = [

  { path: '', redirectTo: '/index', pathMatch: 'full' },
  { path: 'index', component: HomeComponent },
  { path: 'repair',    canActivate: [AuthGuard], loadChildren:'./external/repair/repair.module#RepairModule'  },
  { path: 'diagnose',  canActivate: [AuthGuard], loadChildren:'./external/diagnose/diagnose.module#DiagnoseModule'},
  { path: 'buyPhone',   loadChildren:'./external/buy-phone/buyer.module#BuyerModule'},
  { path: 'sell',     canActivate: [AuthGuard],  loadChildren:'./external/sell/sell.module#SellModule'},
  { path: 'compaire',canActivate: [AuthGuard], loadChildren:'./external/compaire/compaire.module#CompaireModule'}, 
  { path: 'recyclePhone', loadChildren:'./external/recycle/recycle.module#RecycleModule'}, 
  { path: 'setting',  canActivate: [AuthGuard],  loadChildren:'./external/setting/setting.module#SettingModule'  },
  { path: 'push-notification',  canActivate: [AuthGuard],  loadChildren:'./external/notification/notification.module#NotificationModule'  },
  { path: 'chats', canActivate: [AuthGuard], loadChildren:'./external/chat-section/chat.module#ChatModule' },
  { path: 'chats/:chat_dialog_id/:buyer_id/:seller_id/:product_id/:tab', canActivate: [AuthGuard], loadChildren:'./external/chat-section/chat.module#ChatModule' },
  //{ path: 'chats/:chat_dialog_id/:tab', canActivate: [AuthGuard], loadChildren:'./external/chat-section/chat.module#ChatModule' },

  { path: 'deals', canActivate: [AuthGuard],  component: SellerUserComponent },
  { path: 'buyerDeals', canActivate: [AuthGuard], component:BuyerUserComponent },

  { path: 'RecycleHistory',  canActivate: [AuthGuard], loadChildren:'./external/recycle-history/recycleHistory.module#RecycleHistoryModule' },

  { path: 'addCard',  canActivate: [AuthGuard], component:AddCardComponent},
  { path: 'addAccount',   canActivate: [AuthGuard], component:AddAccountComponent},

  { path: 'faq', component:FaqComponent},
  { path: 'privacy-policy', component:PrivacyComponent},
  { path: 'about-us', component:AboutComponent},
  { path: 'terms-and-conditions', component:TermsComponent},
  
  { path: 'newlogin', component: LoginComponent },
  { path: '**', component: PageNotFoundComponent },
 ];

@NgModule({
  imports: [ RouterModule.forRoot(routes,
      {preloadingStrategy: PreloadAllModules}) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
